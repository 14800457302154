import React, { useState } from 'react';

const ImageGallery = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(0);

    const goToPrevious = () => {
        setCurrentImage(currentImage === 0 ? images.length - 1 : currentImage - 1);
    };

    const goToNext = () => {
        setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1);
    };

    return (
        <div className="image-gallery">
            <button onClick={goToPrevious}>&lt;</button>
            <img src={images[currentImage]} alt={`Slide ${currentImage}`} />
            <button onClick={goToNext}>&gt;</button>
        </div>
    );
};

export default ImageGallery;
