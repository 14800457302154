import React, { useState } from "react";
import "./qualification.css";
import Experience from "./Experience";
import Education from "./Education";
// "institution": "University of Wisconsin-Madison",
// "degree": "Bachelor of Computer Science",
// "duration": "Sep 2018 - May 2023"
const Qualification = () => {
  const [toggleState, setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section" id="qualification">
      <h2 className="section__title">Experience</h2>
      <span className="section__subtitle">My personal journey</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleState === 1 ? toggleTab(0) : toggleTab(1)}
          >
            <i className="uil uil-graduation-cap qualification__icon"></i>{" "}
            Education
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__button qualification__active button--flex"
                : "qualification__button button--flex"
            }
            onClick={() => toggleState === 2 ? toggleTab(0) : toggleTab(2)}
          >
            <i className="uil uil-briefcase-alt qualification__icon"></i>{" "}
            Experience
          </div>
        </div>

        <div className="qualification__sections">


          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <Education
              institution="University of Wisconsin-Madison"
              degree="Bachelor of Computer Science"
              time_duration="Sep 2018 - May 2023" />
          </div>

          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <Experience
              position_title="Data Science Intern"
              company_name="Field Day Lab"
              time_duration="Aug 2023 - Present"
              forward={false}
            />
            <Experience
              position_title="Information Technology Volunteer"
              company_name="Madison International Partners"
              time_duration="June 2023 - Present"
              forward={true}
            />
            <Experience
              position_title="Software Developer Engineer Intern"
              company_name="Zenind Inc"
              time_duration="May 2022 - Aug 2022"
              forward={false}
            />
            <Experience
              position_title="Web Developer Intern"
              company_name="Wicky Inc"
              time_duration="May 2021 - Aug 2021"
              forward={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;
