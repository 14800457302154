import React from 'react'
import "./about.css"
import data from "../../data/data.json"
import ImageGallery from './ImageGallery'
import red from "../../assets/red_selfie.jpeg"
import blue from "../../assets/blue_selfie.jpeg"
import grad from "../../assets/graduate_selfie.jpg"
import doph from "../../assets/dophine_kiss_selfie.jpg"
import squa from "../../assets/squat_selfie.jpg"
import goug from "../../assets/with_gougou_selfie.jpg"
import berb from "../../assets/carry_berber_selfie.jpg"

const About = () => {
  const images = [grad, berb, doph, squa, red, goug,  blue]
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My Introduction</span>
      <div className="about__container container grid">
        {/* <img src={AboutImg} alt="About Image" className="about__img" /> */}
        <ImageGallery images={images} />
        <div className="about__data">
          {/* <Info /> */}

          <p className="about__description"> I majored in <strong>Computer Science</strong> and graduated from the University of Wisconsin Madison in 2023 May. I am passionate about using technologies to make an impact to people, communities, and the world.</p>
          <p className="about__description"> During my past years in college, I was actively learning computer science courses including <strong>data structures</strong>, <strong>object-oriented programming</strong>, algorithms, <strong>operating system</strong>, <strong>computer network</strong> and etc.</p>
          <p className="about__description"> I had two internships in Zenind and Wicky where I was responsible for web development using technologies such as <strong>Javascript</strong>, <strong>React</strong> and <strong>Node.js</strong>. I also built a websocket with <strong>Python Flask</strong> and <strong>flask-socketio</strong>, and I learned data visualization skills by using <strong>D3</strong> in Field Day Lab.</p>
          <p className="about__description"> I love scheduling and executing my scheduling. I love working out, swimming, jogging, and e-sports. I love reading psychology and philosophy books. I love Taosim meditation and practices.</p>
          <p className="about__description"> I hope I can become a game engineer or software engineer in the future.</p>
          <a href={data.about.resume_link} target="_blank" className="button button--flex"> Check My Resume &nbsp;
            <i class="uil uil-google-drive-alt"></i>
          </a>
        </div>
      </div>
    </section>
  )
}

export default About